import turnApis from 'Turn/services/apis';
import { MASTER_ADMIN_PIN_CODE } from 'Turn/services/constants';
import { IPinCodeResData } from 'Turn/services/types/staff';
import { Modal as AntModal, Flex, Form, InputRef, Spin } from 'antd';
import { InputOTP } from 'antd-input-otp';
import { debounce } from 'lodash';
import React, { forwardRef, useCallback, useImperativeHandle, useRef, useState, useTransition } from 'react';
import shopSelectors from 'services/selectors/shop';
import styled from 'styled-components';
import closeIcon from '../Close.png';
import NumberPad from './NumberPad';
type Configs = { title?: string, placeholder?: string };
export type PassCodeVerifyRef = {
  checkAdmin: (cb: (pinCode?: string) => void, config?: Configs) => void;
  forceVerifyAdmin: (cb: (pinCode?: string) => void, config?: Configs) => void;
  close: () => void;
  open: (cb: (pinCode: string) => Promise<boolean>, config?: Configs) => void;
  getStaffByPinCode: (cb: (staff: IPinCodeResData) => void, config?: Configs) => void,
};

export const PassCodeVerify = forwardRef<PassCodeVerifyRef>(({ }, ref) => {
  const [open, setOpen] = useState(false);
  const inputRef = useRef<InputRef>(null);
  const [form] = Form.useForm();
  const [wrongPin, setWrongPin] = useState(false);
  const callback = useRef<(pinCode?: string) => void>(() => undefined);
  const callbackPinCodeStaff = useRef<((staff: IPinCodeResData) => void) | null>(null);
  const callbackSuccess = useRef<((pinCode: string) => Promise<boolean>) | null>(null);
  const [loading, setLoading] = useState(false);
  const [configs, setConfigs] = useState<Configs | null>(null);
  const settings = shopSelectors.setting();

  const [, startTransition] = useTransition();

  const handleClose = () => {
    startTransition(() => {
      setOpen(false);
      callbackSuccess.current = null;
      callback.current = () => undefined;
      setWrongPin(false);
      form.resetFields();
      setConfigs(null);
      setLoading(false);
    });
  };

  const handleFinish = async (values: any) => {
    if (callbackPinCodeStaff.current) return handleFinishV2(values);
    if (typeof callbackSuccess.current === 'function') {
      setLoading(true);
      const valid: boolean = await callbackSuccess.current(values.pinCode);
      if (valid) {
        handleClose();
      } else {
        setWrongPin(true);
      }
      setLoading(false);
      return;
    }

    if (values.pinCode === MASTER_ADMIN_PIN_CODE) {
      callback.current(values.pinCode);
      handleClose();
      return;
    }

    setLoading(true);
    try {
      const res: { data: { payload: boolean } } = await turnApis.checkPinCodeAdmin(values.pinCode);
      if (res.data.payload) {
        callback.current(values.pinCode);
        handleClose();
      } else {
        setWrongPin(true);
      }
    } catch (error) {
      setWrongPin(true);
    } finally {
      setLoading(false);
    }
  };

  const handleFinishV2 = async (values: any) => {
    if (!callbackPinCodeStaff.current) return;
    if (values.pinCode === MASTER_ADMIN_PIN_CODE) {
      callbackPinCodeStaff.current({ staffId: +MASTER_ADMIN_PIN_CODE, position: 'Admin', avatar: '', staffName: 'Admin', totalTickets: 0 });
      handleClose();
      return;
    }
    setLoading(true);
    try {
      const res: { data: { payload: IPinCodeResData } } = await turnApis.checkPinCodeAdminV2(values.pinCode);
      if (res.data.payload) {
        callbackPinCodeStaff.current(res.data.payload);
        handleClose();
      } else {
        setWrongPin(true);
      }
    } catch (error) {
      setWrongPin(true);
    } finally {
      setLoading(false);
    }
  };

  useImperativeHandle(ref, () => ({
    checkAdmin(_success, _configs) {
      if (!settings.appointmentVerifyPinCode) return _success(MASTER_ADMIN_PIN_CODE);
      setTimeout(() => {
        inputRef.current?.focus();
      }, 100);
      setOpen(true);
      setWrongPin(false);
      form.resetFields();
      callbackSuccess.current = null;
      setConfigs(_configs || null);
      callback.current = _success;
      callbackPinCodeStaff.current = null;
    },
    forceVerifyAdmin(_success, _configs) {
      setTimeout(() => {
        inputRef.current?.focus();
      }, 100);
      setOpen(true);
      setWrongPin(false);
      form.resetFields();
      callbackSuccess.current = null;
      setConfigs(_configs || null);
      callback.current = _success;
      callbackPinCodeStaff.current = null;
    },
    getStaffByPinCode(_success, _configs) {
      setTimeout(() => {
        inputRef.current?.focus();
      }, 100);
      setOpen(true);
      setWrongPin(false);
      form.resetFields();
      callbackSuccess.current = null;
      setConfigs(_configs || null);
      callbackPinCodeStaff.current = _success;
    },
    close() {
      setOpen(false);
      callbackPinCodeStaff.current = null;
    },
    open(callback, _configs) {
      setTimeout(() => {
        inputRef.current?.focus();
      }, 100);
      setOpen(true);
      setWrongPin(false);
      form.resetFields();
      setConfigs(_configs || null);
      callbackSuccess.current = callback;
      callbackPinCodeStaff.current = null;
    },
  }));

  const debounceFetch = useCallback(debounce((text: string) => {
    if (text.length < 4) return;
    form.submit();
  }, 500), [form]);

  return (
    <AntModal
      centered
      open={open}
      title={null}
      footer={false}
      closable={false}
      destroyOnClose={false}
      width={'620px'}
    >
      <Spin spinning={loading}>
        <Container initialValues={{ pinCode: '' }} form={form} onFinish={handleFinish} onValuesChange={(changedValues: any) => {
          debounceFetch(changedValues.pinCode);
          setWrongPin(false);
        }}>
          <div className="modal-header">
            <span>{configs?.title || ''}</span>
            <button type='button' className='btn-close' onClick={() => setOpen(false)}>
              <img src={closeIcon} />
            </button>
          </div>
          <div className="modal-body">
            <div className="number-pad-placeholder">{configs?.placeholder || 'Enter Pin Code'}</div>
            <div className="wrap-number-input">
              <OTPsFormItem name={'pinCode'}>
                <OTPInput />
              </OTPsFormItem>
              <div style={{ textAlign: 'center', opacity: wrongPin ? 1 : 0 }}><p style={{ color: '#F05326' }}>Wrong Pin Code</p></div>
            </div>
            <Flex align='center' style={{ background: '#E2E7EB' }} justify='center'>
              <Form.Item name={'pinCode'} noStyle>
                <NumberPad inputRef={inputRef} />
              </Form.Item>
            </Flex>
          </div>
        </Container>
      </Spin>
    </AntModal>
  );
});


const OTPsFormItem = styled(Form.Item)`
align-self: stretch;
margin: 0;
.input-otp {
  height: 70px;
  gap: 16px;
  width: 100%;
}
.ant-input {
  display: flex;
  flex: 1;
  padding: 10px;
  padding-bottom: 4px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 10px;
  flex-shrink: 0;
  align-self: stretch;
  border: none;
  border-radius: 0;
  border-bottom: 2px solid #858F9B;


  color: #232F3E;
  font-family: Poppins;
  font-size: 32px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;

  &:active, &:focus {
    border-bottom: 2px solid #005BAA;
    box-shadow: none;
  }
  &.input-otp__field {
    max-width: unset;
    width: unset;
    height: unset;
  }
  &::placeholder {
    color: #CBD5E1;
    opacity: 1; /* Firefox */
  }

  &::-ms-input-placeholder { /* Edge 12-18 */
    color: #CBD5E1;
  }
}
`;

type OTPInputProps = {
  value?: string;
  onChange?: (value: string) => void;
  inputRef?: any;
};

const OTPInput = ({ inputRef, value = '', onChange = () => undefined }: OTPInputProps) => {

  return (
    <InputOTP
      autoFocus value={[...value]}
      length={4} onChange={e => onChange(e.join(''))}
      placeholder='*'
      inputMode='none'
      inputRef={inputRef}
    />
  );
};

const usePassCodeVerify = (): [React.RefObject<PassCodeVerifyRef>, JSX.Element] => {
  const ref = useRef<PassCodeVerifyRef>(null);
  const context = <PassCodeVerify ref={ref} />;
  return [ref, context];
};

export default usePassCodeVerify;

const Container = styled(Form)`
background: #fff;
.modal-header {
  display: flex;
  padding: 0px 16px;
  justify-content: space-between;
  align-items: center;
  align-self: stretch;
  height: 46px;
  border-bottom: 2px solid #EEE;

  span {
    flex: 1 0 0;
    color: #7B7B7B;
    font-family: Poppins;
    font-size: 20px;
    font-style: normal;
    font-weight: 500;
    line-height: 24px;
  }

  .btn-close {
    display: flex;
    width: 32px;
    height: 32px;
    padding: 5.333px;
    justify-content: center;
    align-items: center;

    img {
      height: 100%;
      width: 100%;
    }
  }
}

.modal-body {
padding-top: 8px;
  .number-pad-placeholder {
    align-self: stretch;
    color: #7B7B7B;
    text-align: center;
    font-family: Poppins;
    font-size: 20px;
    font-style: normal;
    font-weight: 500;
    line-height: 24px;
    margin-bottom: 8px;
  }
  
  .wrap-number-input {
    padding: 0 16px;
    padding-bottom: 8px;
  }

  .number-input {
    color: #1D2129;
    text-align: center;
    font-family: Poppins;
    font-size: 28px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
  }
}

`;