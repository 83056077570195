import { CSSProperties, useState } from 'react';
import styled from 'styled-components';
import { RefreshActiveIcon } from './icons';
import full_screen_icon from './full-screen.svg';
import { switchToZoom } from 'Calendar/styles';
import { useAppDispatch } from 'store/hooks';
import aptActions from 'services/actions/apt';
const FullScreenIcon = () => <img src={full_screen_icon} alt="full_screen_icon" style={{ height: 24, width: 24 }} />;

type ActionProps = {
  title?: string;
  onClick?: () => void;
  icon?: () => JSX.Element;
  style?: CSSProperties;
};
const Action = ({ title, onClick, icon, style }: ActionProps) => (
  <ActionStyled className='ActionStyled' onClick={onClick} style={style}>
    {icon && <div className="icon">{icon()}</div>}
    <p >{title}</p>
  </ActionStyled>
);
export const RefreshBtn = () => <Action style={{ minWidth: 'unset', gap: 'unset', padding: '0 8px' }} icon={RefreshActiveIcon} onClick={() => window.location.reload()} />;
export const FULL_SCREEN_CLASS = 'FULL_SCREEN';
export const FullScreenBtn = () => {
  const dispatch = useAppDispatch();
  const [, setActive] = useState(false);
  const onClick = () => {
    const calendar = document.getElementById('calendar-page');
    if (!calendar) return;
    if (calendar.classList.contains(FULL_SCREEN_CLASS)) {
      calendar.classList.remove(FULL_SCREEN_CLASS);
      setActive(true);
      switchToZoom(0);
      dispatch(aptActions.calendar.setDistanceTimeline(15));
    } else {
      setActive(false);
      dispatch(aptActions.calendar.setDistanceTimeline(30));
      switchToZoom(2);
      calendar.classList.add(FULL_SCREEN_CLASS);
    }
  };
  return <Action style={{ minWidth: 'unset', gap: 'unset', padding: '0 8px' }} icon={FullScreenIcon} onClick={onClick} />;
};
export default Action;

const ActionStyled = styled.button`
  border-radius: 2px;
  min-width: 12rem;
  padding: 0 1rem;
  display: flex;
  align-items: center;
  gap: 12px;
  p {
    color: #E8F3FF;
    text-align: center;
    font-family: Poppins;
    font-size: 20px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    text-transform: capitalize;
  }

  .icon {
    display: flex;
    width: 32px;
    height: 32px;
    padding: 1px;
    justify-content: center;
    align-items: center;
    gap: 10px;
    border-radius: 100px;
    background: #FFF;
  }
`;
