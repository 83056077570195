import { Form, FormInstance, InputNumber } from 'antd';
import { delay } from 'lodash';
import React from 'react';
import styled from 'styled-components';
import { IStaffUI } from 'types/staff';
import uuid from 'utils/uuid';
import StaffPicker from 'widgets/Staff/StaffPicker';
import add_icon from './icons/add.svg';
import { QuickCommissionPaymentMethods } from './TicketTableContent';
const addIcon = <img src={add_icon} alt="add_icon" style={{ width: 24, height: 24 }} />;

type Props = {
  value?: IStaffUI;
  onChange?: (value: IStaffUI) => void;
  masterForm: FormInstance<any>;
  form: FormInstance<any>;
  activeStaff?: {
    staffId: string;
    staffName: string;
    position: string;
  } | null;
};

const FormInputs = ({ masterForm, form, activeStaff }: Props) => {
  const inputRef = React.useRef<HTMLInputElement>(null);
  const onAddTicket = (values: any) => {
    const data = values as { staffId: string, numberOfTicket: number };
    const { numberOfTicket, staffId } = data;
    if (!numberOfTicket) return;
    const list = Array.from(Array(numberOfTicket).keys()).map(() => ({
      id: uuid(),
      ticketNumber: 1,
      paymentMethod: QuickCommissionPaymentMethods.CREDIT_CARD,
      sale: null,
      tip: null,
      discount: null,
      supply: null,
      staffId,
      note: '',
    }));
    const tickets = masterForm.getFieldValue('tickets');
    const newList = [...tickets, ...list].map((o, i) => ({
      ...o,
      ticketNumber: i + 1,
    }));
    masterForm.setFieldValue('tickets', newList);
    if (activeStaff?.position === 'Technician') {
      form.resetFields(['numberOfTicket']);
    } else {
      form.resetFields();
    }
  };
  const onChooseStaff = () => delay(() => inputRef.current?.focus(), 100);
  const onEnter = () => setTimeout(() => form.submit(), 100);

  return (
    <Container form={form} layout='inline' name='form' initialValues={{}} onFinish={onAddTicket}>
      <span className='add-more'>Add More Tickets</span>
      <Form.Item noStyle name={'staffId'} rules={[{ required: true, message: 'Required' }]}>
        <StaffPicker disabled={activeStaff?.position === 'Technician'} onChange={onChooseStaff} placeholder={'Choose Staff Add Ticket'} />
      </Form.Item>
      <Form.Item noStyle name={'numberOfTicket'} rules={[{ required: true, message: 'Required' }]}>
        <InputNumberOfTicket inputRef={inputRef} onEnter={onEnter} />
      </Form.Item>
      <div style={{ display: 'flex', alignItems: 'center' }}>
        <button className='btn-add' style={{ height: 48, width: 'auto' }} type='button' onClick={() => form.submit()}>
          {addIcon}
          <span>Add</span>
        </button>
      </div>
    </Container>
  );
};

export default FormInputs;

const Container = styled(Form)`
  display: flex;
  align-items: flex-end;
  gap: 16px;
  align-self: stretch;
  .add-more {
    color: #FFF;
    align-self: center;
    text-align: center;
    font-family: Poppins;
    font-size: 20px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
  }
  .ant-form-item {
    margin: 0;
    .ant-input.ant-input-disabled {
      color: #1D2129;
    }
  }

  .ant-select-selection-placeholder {
    font-size: 1rem;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
  }

  .btn-add {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 4px;
    padding: 0 16px;
    border-radius: 5px;
    background: #148CF1;
    border: 1px solid #148CF1;
    &::hover {
      background: #148CF1;
      border: 1px solid #148CF1;
      opacity: 0.8;
    }
    span {
      color: #FFF;
      text-align: center;
      font-family: Poppins;
      font-size: 16px;
      font-style: normal;
      font-weight: 600;
      line-height: normal;
      text-transform: uppercase;
    }

    &.btn-clear {
      border: 1px solid #86909C;
      background: #F1F1F1;
      span {
        color: #2C333A;
      }
    }
  }
`;

type InputNumberOfTicketProps = {
  value?: number;
  onChange?: (value?: number) => void;
  onEnter?: () => void;
  inputRef?: React.Ref<HTMLInputElement>;
};
const InputNumberOfTicket = ({ inputRef, value, onChange = () => undefined, onEnter = () => undefined }: InputNumberOfTicketProps) => {
  return (
    <InputNumberOfTicketStyled value={value}
      ref={inputRef}
      // @ts-ignore
      onChange={onChange}
      max={25}
      style={{ width: 250 }}
      onKeyPress={(event) => {
        if (!/[0-9]/.test(event.key)) {
          event.preventDefault();
        }
      }}
      onFocus={(event) => {
        event.target.scrollIntoView({ behavior: 'smooth', block: 'center' });
      }}
      type='number'
      onKeyDown={event => {
        if (event.key === 'Enter') {
          onEnter();
          return;
        }
      }}
      placeholder='Enter Number Of Tickets' />
  );
};

const InputNumberOfTicketStyled = styled(InputNumber)`
  height: 48px;
  width: 100%;
  .ant-input-number-input-wrap {
    height: 100%;
    .ant-input-number-input {
      height: 100%; 
      font-size: 1rem;
      font-style: normal;
      font-weight: 600;
      line-height: normal;
    }
  }
`;