import { createAction } from '@reduxjs/toolkit';
import { IApiGetQuickCommissionsParams, IApiGetQuickCommissionsResData, ISummaryQuickCommissionResData } from 'services/types/comm';
import { createAsyncAction } from 'store/actionConfigs';
const PREFIX_ACTION = 'quick-commission';
const commActions = {
  init: createAction<IApiGetQuickCommissionsParams>(PREFIX_ACTION + 'initParamsListQuickCommissions'),
  setParams: createAction<IApiGetQuickCommissionsParams>(PREFIX_ACTION + 'setParamsListQuickCommissions'),
  getSummaryQuickCommissions: createAsyncAction<IApiGetQuickCommissionsParams, ISummaryQuickCommissionResData>(PREFIX_ACTION + 'getSummaryQuickCommissions'),
  getQuickCommissions: createAsyncAction<IApiGetQuickCommissionsParams, IApiGetQuickCommissionsResData>(PREFIX_ACTION + 'getQuickCommissions'),
  getOwnerReportCommissions: createAsyncAction(PREFIX_ACTION + 'getOwnerReportCommissions'),
  getStaffReportCommissions: createAsyncAction(PREFIX_ACTION + 'getStaffReportCommissions'),
};

export default commActions;
