import ModalStaff from 'QuickBooking/ModalStaff';
import { Form, Row, Select } from 'antd';
import DatePicker from 'components/DatePicker';
import { CSSProperties, useEffect } from 'react';
import aptActions from 'services/actions/apt';
import shopSelectors from 'services/selectors/shop';
import { useAppDispatch } from 'store/hooks';
import styled from 'styled-components';
import InputSearchAvatar from 'widgets/Staff/InputSearchAvatar';
import BlockHourList, { useBlockHourListRef } from './BlockHoursList';
import Modal from './Modal';
import ReasonInput from './ReasonInput';
import TimeSlot from './TimeSlot';
import { timeLogOpts } from './helpers';
import bhSignal, { blockHourChooseStaffRef, blockHourInputSearchStaffRef } from './signal';
const contentContainerStyles: CSSProperties = {
  overflow: 'unset',
};
const BlockHoursModal = () => {
  const dispatch = useAppDispatch();
  const handleClose = () => bhSignal.close();
  const staffList = shopSelectors.staff();
  const blockHoursRef = useBlockHourListRef();

  useEffect(() => {
    if (!bhSignal.openSignal.value) return;
    blockHourInputSearchStaffRef.current?.setStaff(bhSignal.staffIdSignal.value);
  }, [bhSignal.openSignal.value]);

  const handleBlockHours = async () => {
    const payload = bhSignal.getPayload();
    if (!payload) return null;
    bhSignal.openSignal.value = false;

    const id = bhSignal.detailSignal.value?.id;
    if (id) {
      dispatch(aptActions.updateBlockHour({ ...payload, id: +id }));
    } else {
      dispatch(aptActions.addBlockHour(payload));
    }
  };

  const handleDelete = async () => {
    const id = bhSignal.detailSignal.value?.id;
    if (!id) return;
    bhSignal.openSignal.value = false;
    dispatch(aptActions.deleteBlockHour(id));
  };

  useEffect(() => {
    if (!bhSignal.openSignal.value) return;
    blockHoursRef.current?.fetchList(bhSignal.staffIdSignal.value, bhSignal.activeDateSignal.value?.format('YYYY-MM-DD') || '');
  }, [bhSignal.activeDateSignal.value, bhSignal.staffIdSignal.value]);


  return (
    <>
      <Modal
        title="BLOCK HOURS"
        open={bhSignal.openSignal.value}
        onCancel={handleClose}
        okText={bhSignal.detailSignal.value?.id ? 'UPDATE' : 'CREATE BLOCK HOURS'}
        onOk={handleBlockHours}
        updateAptFooter={!!bhSignal.detailSignal.value?.id}
        onDelete={handleDelete}
        onUpdate={handleBlockHours}
        width='1600px'
        contentContainerStyles={contentContainerStyles}
      >
        <Container className='container-div'>
          <FormStyled>
            <div className="scroll-div">
              <Form.Item label='STAFF' validateStatus={bhSignal.errorStaffSignal.value ? 'error' : undefined} help={bhSignal.errorStaffSignal.value}>
                <InputSearchAvatar ref={blockHourInputSearchStaffRef} onSelect={bhSignal.onSelectStaff} />
              </Form.Item>
              <RowTime>
                <Form.Item label='DATE'>
                  <DatePicker value={bhSignal.activeDateSignal.value} onChange={bhSignal.onChangeActiveDate} />
                </Form.Item>
                <Form.Item label='DURATION'>
                  <Select
                    size='large'
                    defaultValue={15}
                    options={timeLogOpts}
                    value={bhSignal.durationSignal.value}
                    onChange={bhSignal.onChangeDuration}
                  />
                </Form.Item>
              </RowTime>
              <Form.Item label='REASON' validateStatus={bhSignal.errorReasonSignal.value ? 'error' : undefined} help={bhSignal.errorReasonSignal.value}>
                <ReasonInput value={bhSignal.reasonSignal.value} onChange={bhSignal.onChangeReason} />
              </Form.Item>
              <div>
                <div className="form-label">START TIME</div>
                <TimeSlot value={bhSignal.startTimeSlot.value} onChange={bhSignal.onChangeStartTime} />
                <Form.Item validateStatus={bhSignal.errorTimeSlotSignal.value ? 'error' : undefined} help={bhSignal.errorTimeSlotSignal.value} />
              </div>
            </div>
          </FormStyled>
          <BlockHourList ref={blockHoursRef} />
        </Container>
      </Modal>
      <ModalStaff title='CHOOSE STAFF' ref={blockHourChooseStaffRef} staffList={staffList} />
    </>
  );
};

export default BlockHoursModal;

const Container = styled.div`
  display: flex;
  flex: 1;
  position: relative;
  .scroll-div {
    position: absolute;
    inset: 0;
    overflow-y: auto;
    -ms-overflow-style: none;
    scrollbar-width: none;
    &::-webkit-scrollbar {
      display: none;
    }
  }
`;

const RowTime = styled(Row)`
  display: flex;
  align-items: center;
  gap: 16px;
  align-self: stretch;
  .ant-form-item {
    flex: 1;
  }
`;

const FormStyled = styled.div`
  position: relative;
  flex: 1;
  .scroll-div {
    padding: 1rem 1rem;
  }
  .group {
    gap: 1.5rem;
  }
  .col-item {
    flex:1;
  }
  input.ant-input {
    line-height: 2;
    &::placeholder {
      color:#dadada;
    }
  }
  .ant-form-item-row {
    display: flex;
    flex-direction: column;
    .ant-form-item-label {
      text-align: left;
    }
  }

  .ant-select-single.ant-select-lg {
    height: 56px;
  }
  .form-label {
    color: #7B7B7B;
    font-family: Poppins;
    font-size: 20px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px;
  }
  .ant-form-item .ant-form-item-label >label {
    color: #7B7B7B;
    font-family: Poppins;
    font-size: 20px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px; /* 120% */
  }
  &.ant-form-large .ant-form-item .ant-form-item-label>label {
    height: 24px;
    color: #7B7B7B;
    font-family: Poppins;
    font-size: 20px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px; /* 120% */
  }

  .ant-select-single.ant-select-lg .ant-select-selector {
    font-size: 1.2rem;
    font-weight: 500;
    font-family: Poppins;
  }
`;