import { createSlice } from '@reduxjs/toolkit';
import { IStaffUI } from 'types/staff';
import actions from '../actions/shop';
import { ISalonInfoResData, IScheduleBooking } from 'services/types/shop';
import { ICategoryUI } from 'types/category';
import { IServiceUI } from 'types/service';
import { ICustomerUI } from 'types/customer';
import { ISchedulerSalonResData } from 'services/types/response';

const _scheduleBooking = {
  ['Sun']: {
    enable: true,
    endTime: '21:00',
    startTime: '08:00',
  },
  ['Sat']: {
    enable: true,
    endTime: '21:00',
    startTime: '08:00',
  },
  ['Fri']: {
    enable: true,
    endTime: '21:00',
    startTime: '08:00',
  },
  ['Thu']: {
    enable: true,
    endTime: '21:00',
    startTime: '08:00',
  },
  ['Wed']: {
    enable: true,
    endTime: '21:00',
    startTime: '08:00',
  },
  ['Tue']: {
    enable: true,
    endTime: '21:00',
    startTime: '08:00',
  },
  ['Mon']: {
    enable: true,
    endTime: '21:00',
    startTime: '08:00',
  },
};

const NAME_REDUCER = 'shop';

export type IStateShop = {
  staffs: IStaffUI[],
  staffsObject: Record<string, IStaffUI>,
  staffsAvailable: IStaffUI[],
  scheduleBooking: IScheduleBooking | null,
  salonWorking: ISchedulerSalonResData | null,
  categories: ICategoryUI[],
  allServices: IServiceUI[],
  customers: ICustomerUI[],
  setting: {
    appointmentVerifyPinCode: boolean;
  },
  salonInfo: ISalonInfoResData | null;
}
const initialState: IStateShop = {
  staffs: [],
  staffsObject: {},
  staffsAvailable: [],
  scheduleBooking: _scheduleBooking,
  salonWorking: null,
  categories: [],
  allServices: [],
  customers: [],
  setting: {
    appointmentVerifyPinCode: false,
  },
  salonInfo: null,
};

export const Slice = createSlice({
  name: NAME_REDUCER,
  initialState,
  reducers: {},
  extraReducers: builder => {
    builder
      .addCase(actions.salonInfo.success, (state, { payload }) => {
        state.salonInfo = payload;
      })
      .addCase(actions.scheduler.success, (state, { payload }) => {
        state.salonWorking = payload;
        const _scheduleBooking = {
          ['Sun']: {
            enable: !!payload.sunday,
            endTime: payload.sunEndTime,
            startTime: payload.sunStartTime,
          },
          ['Sat']: {
            enable: !!payload.saturday,
            endTime: payload.satEndTime,
            startTime: payload.satStartTime,
          },
          ['Fri']: {
            enable: !!payload.friday,
            endTime: payload.friEndTime,
            startTime: payload.friStartTime,
          },
          ['Thu']: {
            enable: !!payload.thursday,
            endTime: payload.thuEndTime,
            startTime: payload.thuStartTime,
          },
          ['Wed']: {
            enable: !!payload.wednesday,
            endTime: payload.wedEndTime,
            startTime: payload.wedStartTime,
          },
          ['Tue']: {
            enable: !!payload.tuesday,
            endTime: payload.tueEndTime,
            startTime: payload.tueStartTime,
          },
          ['Mon']: {
            enable: !!payload.monday,
            endTime: payload.monEndTime,
            startTime: payload.monStartTime,
          },
        };
        state.scheduleBooking = _scheduleBooking;
      })
      .addCase(actions.category.success, (state, { payload }) => {
        state.categories = payload;
        const services: IServiceUI[] = [];
        state.categories.map(cate => {
          cate.services.map(s => {
            services.push({
              ...s,
              cateId: cate.id,
            });
          });
        });
        state.allServices = services;
      })
      .addCase(actions.staffAvailable.success, (state, { payload }) => {
        state.staffsAvailable = payload;
      })
      .addCase(actions.staff.success, (state, { payload }) => {
        state.staffs = payload;
        payload.map((o: IStaffUI) => {
          state.staffsObject[o.id] = o;
        });
      })
      .addCase(actions.customer.success, (state, { payload }) => {
        state.customers = payload;
      })
      .addCase(actions.afterCreateCustomer, (state, { payload }) => {
        state.customers.pop();
        state.customers.unshift(payload);
      })
      .addCase(actions.afterCreateService, (state, { payload }) => {
        state.allServices.unshift(payload);
        state.categories = state.categories.map(o => {
          if (o.id?.toString() === payload.cateId?.toString()) return ({
            ...o,
            services: [payload, ...o.services],
          });
          return o;
        });
      })
      ;
  },
});

const shopReducer = Slice.reducer;
export default shopReducer;
