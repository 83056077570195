import NewAppointment from 'AppointmentBox';
import ModalConfirmSMSChange, { modalConfirmSMSChangeRef } from 'AppointmentBox/ModalConfirmSMSChange';
import BlockHoursModal from 'BlockHourBox';
import QuickCommission, { globalQuickCommissionRef } from 'Quick-Commission';
import QuickBookingBox from 'QuickBooking';
import ShopWrapperLayout from 'ShopWrapperLayout';
import BookingPage from 'pages/BookingPage';

import { PassCodeVerify } from 'Turn/TurnModals/PassCodeVerify';
import { ConfigProvider } from 'antd';
import LoadingPage from 'components/LoadingPage';
import React, { Suspense } from 'react';
import { Provider } from 'react-redux';
import { Route, Routes } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import useInitSocket, { SocketContext } from 'services/useSocket';
import store from 'store';
import ModalConfirm, { modalConfirmRef } from 'widgets/ModalConfirm';
import { verifyPinCode } from 'widgets/ModalVerifyAdminPin';

const ReceiptReviewWrapperLayout = React.lazy(() => import('Receipts'));
const QuickCommissionReceiptOwner = React.lazy(() => import('Receipts/QuickCommisison/receipt.owner'));
const QuickCommissionReceiptStaff = React.lazy(() => import('Receipts/QuickCommisison/receipt.staff'));
const TurnAppointmentsPage = React.lazy(() => import('Turn/TurnAppointments'));
const AppointmentListPage = React.lazy(() => import('pages/AppointmentListPage'));
const TurnLayout = React.lazy(() => import('Turn/TurnLayout'));
const TurnSchedulerPage = React.lazy(() => import('Turn/TurnScheduler'));
const NotFoundPage = React.lazy(() => import('NotFound'));

const AppLayout: React.FC = () => {
  const socketContext = useInitSocket();
  return (
    <SocketContext.Provider value={socketContext}>
      <Suspense fallback={<></>}>
        <Routes>
          <Route path='/:shop_id' element={<ShopWrapperLayout />} >
            <Route path='' element={<BookingPage />} />
            <Route path='receipt' element={<ReceiptReviewWrapperLayout />}>
              <Route path='quick-commission/staff' element={<QuickCommissionReceiptStaff />} />
              <Route path='quick-commission/owner' element={<QuickCommissionReceiptOwner />} />
            </Route>
            <Route path='list' element={<AppointmentListPage />} />
            <Route path='turn' element={<TurnLayout />}>
              <Route path='' element={<TurnSchedulerPage />} />
              <Route path='list' element={<TurnAppointmentsPage />} />
            </Route>
          </Route>
          <Route path='*' element={<NotFoundPage />} />
        </Routes>
      </Suspense>
    </SocketContext.Provider>
  );
};

const App: React.FC = () => (
  <ConfigProvider
    theme={{
      'token': {
        'colorPrimary': '#F05326',
        'colorPrimaryBg': '#FFF1F6',
        'colorPrimaryActive': '#F05326',
        'colorText': '#1D2129',
        'colorTextSecondary': '#767676',
        'colorBorder': '#86909C',
        'colorBorderSecondary': '#CECECE',
        'colorFill': '#E5E6EB',
        'colorFillSecondary': '#E5E5E5',
        'colorFillTertiary': '#F6F7FC',
        'colorBgLayout': '#E5E5E5',
        'colorBgSpotlight': '#1D2129',
        'colorBgMask': 'rgba(0, 0, 0, 0.2)',
        'colorTextBase': '#1d2129',
        'colorBgBase': '#ffffff',
        'colorWarning': '#ff7d00',
        'colorError': '#f53f3f',
        'colorInfo': '#6fabb6',
        'fontSize': 16,
        'sizeStep': 4,
        'borderRadius': 5,
        'borderRadiusXS': 2,
        'wireframe': false
      },
    }}
  >
    <Provider store={store}>
      <AppLayout />
      <ToastContainer />
      <NewAppointment />
      <BlockHoursModal />
      <QuickBookingBox />
      <QuickCommission ref={globalQuickCommissionRef} />
      <ModalConfirmSMSChange ref={modalConfirmSMSChangeRef} />
      <ModalConfirm ref={modalConfirmRef} />
      <PassCodeVerify ref={verifyPinCode} />
      <LoadingPage />
    </Provider>
  </ConfigProvider>
);

export default App;

{/* <NumberKeyboardListener /> */ }
// 