import { Modal, ModalProps, Spin } from 'antd';
import clsx from 'clsx';
import { CSSProperties, memo } from 'react';
import styled from 'styled-components';
export type ModalDarkProps = {
  title?: string;
  open?: boolean;
  onClose?: () => void;
  children?: any;
  FooterComponent?: () => any;
  width?: ModalProps['width'];
  loading?: boolean;
  RightActionComponent?: () => any;
  titleAlign?: CSSProperties['textAlign'];
  containerStyles?: CSSProperties;
  headerStyles?: CSSProperties;
  bodyStyles?: CSSProperties;
  footerStyles?: CSSProperties;
  Wrapper?: React.FC<{ children?: any }>;
  containerClassName?: string;
};
const ModalDark = ({ containerClassName, headerStyles, footerStyles, bodyStyles, containerStyles, Wrapper, titleAlign = 'center', loading = false, width = 'auto', title = 'modal', open, onClose, children, FooterComponent = () => null, RightActionComponent = () => undefined }: ModalDarkProps) => {
  if (Wrapper) {
    return (
      <Modal keyboard forceRender width={width} footer={null} open={open} onCancel={onClose} closeIcon={null}>
        <Wrapper>
          <Container style={containerStyles} className={containerClassName} >
            <HeaderStyled style={headerStyles}>
              <span className={clsx('modal-title', titleAlign)}>{title}</span>
              <RightActionComponent />
            </HeaderStyled>
            <BodyStyled style={bodyStyles}>{children}</BodyStyled>
            <FooterStyled style={footerStyles}><FooterComponent /></FooterStyled>
          </Container>
        </Wrapper>
        {loading && <Modal centered width={'120px'} open forceRender footer={null} closeIcon={null}><div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', height: 120 }}><Spin size='large' /></div></Modal>}
      </Modal>
    );
  }
  return (
    <Modal centered keyboard forceRender width={width} footer={null} open={open} onCancel={onClose} closeIcon={null}>
      <>
        <Container style={containerStyles} className={containerClassName}>
          <HeaderStyled style={headerStyles}>
            <span className={clsx('modal-title', titleAlign)}>{title}</span>
            <RightActionComponent />
          </HeaderStyled>
          <BodyStyled style={bodyStyles}>{children}</BodyStyled>
          <FooterStyled style={footerStyles}><FooterComponent /></FooterStyled>
        </Container>
      </>
      {loading && <Modal centered width={'120px'} open forceRender footer={null} closeIcon={null}><div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', height: 120 }}><Spin size='large' /></div></Modal>}
    </Modal>
  );
};

export default memo(ModalDark);

const Container = styled.div`
display: flex;
flex-direction: column;
justify-content: center;
align-items: center;
gap: 24px;
min-width: 70vw;

@media screen and (max-width: 1225px) {
  &.quick-commission {
    zoom: 0.8;
  }
}
`;

const HeaderStyled = styled.div`
display: flex;
padding: 10px 16px;
justify-content: space-between;
align-items: flex-start;
gap: 10px;
align-self: stretch;
border-radius: 5px;
border-bottom: 1px solid #D4D7E4;
background: #232F3E;
position: relative;
min-height: 40px;
.btn-close {
display: flex;
height: 40px;
padding: 0px 16px;
justify-content: center;
align-items: center;
gap: 10px;
border-radius: 5px;
background: #F05326;
  span {
    color: #FFF;
    text-align: center;
    font-family: Poppins;
    font-size: 18px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
  }
}

span.modal-title {
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 1;
  overflow: hidden;
  color: #FFF;
  text-align: center;
  text-overflow: ellipsis;
  font-family: Poppins;
  font-size: 20px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  text-transform: uppercase;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%,-50%);

  &.left {
    left: 16px;
    transform: translateY(-50%);
  }

}
`;

const BodyStyled = styled.div`
align-self: stretch;
padding: 0 16px;
`;
const FooterStyled = styled.div`
display: flex;
padding: 12px 24px 24px 24px;
align-items: center;
gap: 8px;
align-self: stretch;
background: #FFF;
box-shadow: 0px -8px 16px -8px rgba(0, 0, 0, 0.15);

.btn {
  display: flex;
  height: 56px;
  padding: 12px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  flex: 1 0 0;
  border-radius: 5px;
  border: 1px solid #86909C;
  background: #F1F1F1;
  img.icon {
    display: flex;
    width: 24px;
    height: 24px;
    padding: 1px 1.465px 0.391px 1px;
    justify-content: center;
    align-items: center;
  }

  span {
    color: #2C333A;
    text-align: center;
    font-family: Poppins;
    font-size: 20px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    text-transform: uppercase;
  }

  &.btn-submit {
    background: #148CF1;
    border: 1px solid #148CF1;
    span {
      color: #FFF;
    }

    &.outline {
      border: 1px solid #148CF1;
      background: #fff;
      span {
        color: #148CF1;
      }
    }
  }

  &:active {
    opacity: 0.8;
  }
  &:disabled {
    opacity: 0.3;
  }
}
`;