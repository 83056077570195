import { Avatar, Select } from 'antd';
import Text from 'components/Text';
import shopSelectors from 'services/selectors/shop';
type Props = {
  value?: string;
  onChange?: (value: string) => void;
  placeholder?: string;
  disabled?: boolean;
};
const StaffPicker = ({ disabled, placeholder, value: staffId, onChange: setStaffId = () => undefined }: Props) => {
  const staffs = shopSelectors.staff();

  return (
    <Select disabled={disabled} placeholder={placeholder} onChange={setStaffId} value={staffId} style={{ width: 250, height: 'auto', alignSelf: 'stretch' }}>
      {!placeholder && <Select.Option value={''}>
        <div style={{ display: 'flex', alignItems: 'center', gap: 8 }}>
          <Avatar size={32} >All</Avatar>
          <Text variant='CONTENT_2'>All staff</Text>
        </div>
      </Select.Option>}
      {staffs?.map(o => (
        <Select.Option key={o.id} value={o.id}>
          <div style={{ display: 'flex', alignItems: 'center', gap: 8 }}>
            <Avatar size={32} src={o.avatar} >{o.name?.[0] || 'A'}</Avatar>
            <Text variant='CONTENT_2'>{o.name}</Text>
          </div>
        </Select.Option>
      ))}
    </Select>
  );
};

export default StaffPicker;
