import { Empty, Spin } from 'antd';
import { AxiosResponse } from 'axios';
import moment from 'moment';
import React, { forwardRef, useImperativeHandle, useRef, useState } from 'react';
import aptActions from 'services/actions/apt';
import apis from 'services/apis';
import { IBlockHourResItem } from 'services/types/blockHours';
import { useAppDispatch } from 'store/hooks';
import styled from 'styled-components';
import { modalConfirmRef } from 'widgets/ModalConfirm';
type Props = {};
type Ref = {
  fetchList: (staffId: string, date: string) => void;
};
type IUIBlockHourItem = {
  id: number;
  reason: string;
  date: string;
  time: string
};
export const useBlockHourListRef = () => useRef<Ref>(null);
const BlockHourList = forwardRef<Ref, Props>(({ }, ref) => {
  const dispatch = useAppDispatch();
  const paramsCache = useRef<{ staffId: string, date: string }>({ staffId: '', date: '' });
  const [loading, setLoading] = useState(true);
  const [list, setList] = useState<IUIBlockHourItem[]>([]);

  const getList = async (staffId: string, date: string) => {
    setList([]);
    setLoading(true);
    try {
      const res: AxiosResponse<{ payload: IBlockHourResItem[] }> = await apis.getBlockHoursByStaffId(staffId, date);
      if (res.data.payload) {
        setList(res.data.payload.map(o => {
          const startTimeMoment = moment(o.startTime, 'MM-DD-YYYY hh:mm A');
          const endTimeMoment = moment(o.endTime, 'MM-DD-YYYY hh:mm A');
          const date = startTimeMoment.format('MM-DD-YYYY');
          const time = `${startTimeMoment.format('hh:mm A')} to ${endTimeMoment.format('hh:mm A')}`;
          return { id: o.id, reason: o.reason, date, time };
        }));

      }
    } catch (error) {
    } finally {
      setLoading(false);
    }
  };

  const handleDeleteBlockHour = (id: number) => async () => modalConfirmRef.current?.open(async () => {
    setLoading(true);
    try {
      const res: AxiosResponse<{ payload: IBlockHourResItem }> = await apis.deleteBlockHours(id?.toString() || '');
      if (res.data.payload) {
        const { staffId, date } = paramsCache.current;
        dispatch(aptActions.onFormDeleteBlockHourSuccess(date));
        await getList(staffId, date);
      }
    } catch (error) {
    } finally {
      setLoading(false);
    }
  },
    'Unlock block hour', 'Are you sure you want to unlock this block hour?');

  useImperativeHandle(ref, () => ({
    fetchList: async (staffId: string, date: string) => {
      paramsCache.current = { staffId, date };
      await getList(staffId, date);
    }
  }));
  return (
    <BlockHoursStyled>
      <p className="title">Block hours</p>
      <div className="container-block-hours">
        <div className="scroll-div">
          <BlockHourTableStyled>
            <thead>
              <tr>
                <th className='date'>DATE</th>
                <th className='time'>TIME</th>
                <th>REASON</th>
                <th className="actions-block">ACTION</th>
              </tr>
              <tr className='divider'><th style={{ boxShadow: 'none', top: '32px' }} colSpan={4} ><hr style={{ border: 'none', margin: '2px 0' }} className='head' /></th></tr>
              {loading && <tr><td colSpan={4} style={{ textAlign: 'center' }}><Spin spinning /></td></tr>}
            </thead>
            <tbody hidden={loading}>
              {list.map((o, i) => {
                return (
                  <React.Fragment key={o.id}>
                    <tr>
                      <td >{o.date}</td>
                      <td >{o.time}</td>
                      <td>{o.reason}</td>
                      <td>
                        <div className="actions" onClick={handleDeleteBlockHour(o.id)}>
                          <button type='button' className='btn btn-unlock'><span>unlock</span></button>
                        </div>
                      </td>
                    </tr>
                    {list.length - 1 !== i && <tr className='divider'><td colSpan={4} ><hr /></td></tr>}
                  </React.Fragment>
                );
              })}
              {list.length === 0 && <tr><td colSpan={4} style={{ textAlign: 'center' }}><Empty /></td></tr>}
            </tbody>
          </BlockHourTableStyled>
        </div>
      </div>
    </BlockHoursStyled>
  );
});
BlockHourList.displayName = 'BlockHourList';
export default BlockHourList;

const BlockHourTableStyled = styled.table`
  align-self: stretch;
  color: #2C333A;
  font-family: Poppins;
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  text-align: left;
  border-collapse: collapse;

  thead tr th {
    color: #484F58;
    font-weight: 600;
    line-height: 30px;
    text-transform: uppercase;
    position: sticky;
    top: 0;
    background: #FFF;
    box-shadow: inset 0px -1px 0px #86909C;
    &.time {
      width: 185px;
      padding-right: 12px;
    }
    &.date {
      width: 120px;
      padding-right: 12px;
    }
    &.actions-block {
      width: 150px;
    }
  }

  hr {
    display: block;
    height: 1px;
    border: 0;
    border-top: 1px solid #E1E1E1;
    margin: 4px 0;
    padding: 0;
  }
  tbody tr {
   
    .actions {
      width: 100%;
      display: flex;
      button.btn {
        display: flex;
        height: 48px;
        padding: 0 12px;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        gap: 10px;
        flex: 1 0 0;
        border-radius: 2px;
        background: #148CF1;
        &:active {
          opacity: 0.8;
        }
        span {
          color: #FFF;
          text-align: center;
          font-family: Poppins;
          font-size: 18px;
          font-style: normal;
          font-weight: 600;
          line-height: normal;
          text-transform: uppercase;
        }
      }
    }
  }
`;

const BlockHoursStyled = styled.div`
display: flex;
flex-direction: column;
gap: 32px;
align-self: stretch;
flex: 1;
background: #FFF;
padding: 24px;
padding-bottom: 0;

.container-block-hours {
  position: relative;
  flex: 1;
  .scroll-div {
    display: flex;
    flex-direction: column;
  }
}
  .title {
    color: #2C333A;
    font-family: Poppins;
    font-size: 24px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    text-transform: uppercase;
  }
`;