import { Spin } from 'antd';
import styled from 'styled-components';
import bhSignal from './signal';
type Props = {
  value: string;
  onChange: (val: string) => void;
};
export type BlockHourTimeSlotRef = {
  fetch: (staffId: string, date: string) => void;
};

const BlockHourTimeSlots = ({ value, onChange }: Props) => {
  const loading = bhSignal.timeSlotLoadingSignal.value;

  if (loading) return <Spin />;
  return (
    <Times>
      {bhSignal.listTimeSlotSignal.value.map(o => (
        <Time key={o} data={o} active={o === value} onClick={() => onChange(o)} />
      ))}
    </Times>
  );
};

export default BlockHourTimeSlots;


const Times = styled.div`
  margin-top: 12px;
  display: grid;
  gap: 12px;
  grid-template-columns: repeat(auto-fill,minmax(100px, 1fr));
`;

type TimeProps = {
  data: string;
  onClick?: () => void;
  active?: boolean;
};
const Time = ({ data, onClick, active }: TimeProps) => {
  return (
    <TimeStyled onClick={onClick} className={`${active ? 'active' : ''}`}>
      <p className="time">{data}</p>
    </TimeStyled>
  );
};

const TimeStyled = styled.button`
width: 100%;
height: 56px;
display: flex;
flex-direction: column;
align-items: center;
justify-content: center;
border: 1px solid #FFF;

border-radius: 5px;
border: 1px solid #86909C;
background: #FFF;

&:hover {
  background: #C5DDF8;
}

.time {
  color: #1D2129;
  text-align: center;
  font-family: Poppins;
  font-size: 20px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}
&.active {
  border-radius: 5px;
  background: #148CF1;
  .time {
    color: #FFF;
    text-align: center;
    font-family: Poppins;
    font-size: 18px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
  }
}


`;
