import { AxiosResponse } from 'axios';
import fetch from './request';
import { ICategoryResItem, IServiceResItem } from './types/response';
import { IAppointmentListParams, IPayloadAddNewCustomer, IPayloadBooking, IPayloadBookingV2, IPayloadDragADrop } from './types/request';
import storage from 'utils/sessionStorage';
import { IBodyUpdateCustomerDetail } from './types/apt';
import { IAddNewServicePayload } from './types/shop';
import { IBodyAddBlockHours } from './types/blockHours';
import { IBodyApiQuickBooking } from './types/quickBooking';
import { AppointmentStatus } from 'Turn/services/constants';
import { ICustomerUI } from 'types/customer';
import { IApiAddCommissionBody, IApiGetQuickCommissionsParams, IApiUpdateCommissionBody } from './types/comm';

const getCategories = (shopId: string): Promise<AxiosResponse<{ payload: ICategoryResItem[] }>> => {
  return fetch({
    url: 'api/v1/service/category/list',
    shopId,
  });
};

const getServices = (shopId: string): Promise<AxiosResponse<{ payload: IServiceResItem[] }>> => {
  return fetch({
    url: 'api/v1/service/list',
    shopId,
  });
};
const getStaffs = (shopId: string) => {
  return fetch({
    url: 'api/v1/staff/list',
    shopId,
  });
};

const getStaffsAvailable = (shopId: string, currentDate: string) => {
  return fetch({
    url: 'api/v1/staff/availableBooking',
    shopId,
    params: { currentDate },
  });
};

const getAppointments = (shopId: string, start_time: string) => {
  return fetch({
    url: 'api/v1/appointment/listAppointmentRealtime',
    shopId,
    params: { start_time },
  });
};

const bookAppointment = (shopId: string, body: IPayloadBooking) => {
  return fetch({
    url: 'api/v1/appointment/v2/bookingAppointment',
    shopId,
    method: 'post',
    body,
  });
};

const bookAppointmentV2 = (shopId: string, payload: IPayloadBookingV2) => {
  return fetch({
    url: 'api/v1/appointment/bookingV3',
    shopId,
    method: 'post',
    body: { payload },
  });
};

const getTimeSlot = (shopId: string, currentDate: string, staffId: string) => {
  return fetch({
    method: 'get',
    url: 'api/v1/appointment/getAvailableTimeSlotByStaff/',
    params: { currentDate, staffId },
    shopId,
  });
};

const deleteApt = (shopId: string, aptId: string, pinCode: string) => {
  return fetch({
    method: 'delete',
    url: 'api/v1/appointment/deleteAppointment/' + aptId,
    shopId,
    pinCode,
  });
};

const cancelApt = (shopId: string, payload: { aptId: string, reason: string, pinCode: string }) => {
  return fetch({
    method: 'put',
    url: '/api/v1/appointment/update-status',
    shopId,
    body: {
      appointmentId: payload.aptId, reason: payload.reason,
      pinCode: payload.pinCode,
      status: AppointmentStatus.CANCEL,
    },
    pinCode: payload.pinCode,
  });
};
const confirmApt = (shopId: string, payload: { appointmentId: string, pinCode: string }) => {
  return fetch({
    method: 'put',
    url: '/api/v1/appointment/update-status',
    shopId,
    body: {
      appointmentId: payload.appointmentId, status: AppointmentStatus.CONFIRMED,
      pinCode: payload.pinCode,
    },
    pinCode: payload.pinCode,
  });
};
export type CheckInAptPayload = {
  id: string;
  customer: ICustomerUI;
  staffId: string;
  time: string;
  totalDuration: number;
  services: string[];
  note?: string;
  isViewOnly?: boolean;
  requestStaff?: boolean;
  status?: AppointmentStatus;
};
const checkInApt = (shopId: string, appointmentId: string) => {
  return fetch({
    method: 'post',
    url: `/api/v1/appointment/checkIn/${appointmentId}`,
    shopId,
  });
};

const updateApt = (shopId: string, body: IPayloadBooking) => {
  return fetch({
    method: 'put',
    url: 'api/v1/appointment/v2/updateAppointment',
    shopId,
    body,
    pinCode: body?.payload?.pinCode || '',
  });
};

const getAllCustomerAutoSearchPage = (shopId: string, keyWord?: string) => {
  return fetch({
    method: 'get',
    url: 'api/v1/customer/getAllCustomerAutoSearchPage',
    shopId,
    params: { keyWord },
  });
};

const updateDragAndDrop = (shopId: string, body: IPayloadDragADrop) => {
  return fetch({
    method: 'put',
    url: 'api/v1/appointment/update-with-drop',
    body,
    shopId,
    pinCode: body.payload?.pinCode || '',
  });
};

const addNewCustomer = (shopId: string, payload: IPayloadAddNewCustomer) => {
  return fetch({
    method: 'post',
    url: 'api/v1/customer/save',
    body: {
      type: 'FEEDBACK/DASHBOARD/ON_CREATE_CUSTOMER',
      payload,
    },
    shopId,
  });
};

const getAppointmentLists = (shopId: string, params: IAppointmentListParams) => {
  return fetch({
    method: 'get',
    url: 'api/v1/appointment/getListAppointment',
    params: { ...params, pageSize: 20 },
    shopId,
  });
};

const prolongedTime = (appointmentId: string, status: 'ADD' | 'MINUS', pinCode: string) => {
  return fetch({
    method: 'post',
    url: 'api/v1/appointment/prolongedTime',
    body: {
      payload: { appointmentId, status, pinCode },
    },
    shopId: storage.shopId.get(),
    pinCode,
  });
};

const checkPinCode = (shopId: string, pinCode: string) => {
  return fetch({
    method: 'post',
    url: 'api/v1/staff/checkPinCode',
    body: {
      payload: { pinCode },
    },
    shopId,
  });
};

const getDetailCustomer = (customerId: string) => {
  return fetch({
    method: 'get',
    url: `api/v1/customer/getDetailCustomer/${customerId}`,
    shopId: storage.shopId.get(),
  });
};

const updateCustomerDetail = (customerId: string, body: IBodyUpdateCustomerDetail) => {
  return fetch({
    method: 'put',
    url: `api/v1/customer/updateCustomer/${customerId}`,
    shopId: storage.shopId.get(),
    body: { payload: body }
  });
};

const addNewService = (payload: IAddNewServicePayload) => {
  return fetch({
    method: 'post',
    url: '/api/v1/service/save',
    shopId: storage.shopId.get(),
    body: { payload },
  });
};

const addBlockHours = (payload: IBodyAddBlockHours) => {
  return fetch({
    method: 'post',
    url: '/api/v1/appointment/blockHours',
    shopId: storage.shopId.get(),
    body: { payload },
  });
};

const removeBlockHour = (id: string) => {
  return fetch({
    method: 'delete',
    url: '/api/v1/appointment/deleteBlockHours/' + id,
    shopId: storage.shopId.get(),
  });
};
const prolongedTimeBlockHour = (blockHourId: string, type: 'ADD' | 'MINUS') => {
  return fetch({
    method: 'post',
    url: 'api/v1/appointment/updateBlockHours',
    body: {
      payload: { id: +blockHourId, type },
    },
    shopId: storage.shopId.get(),
  });
};

const getAllBlockHours = (shopId: string, startTime: string) => {
  return fetch({
    url: 'api/v1/appointment/blockHours',
    shopId,
    params: { startTime },
  });
};
const getAllBlockHoursAptList = (shopId: string, params: { startTime: string, staffId?: string, endTime: string }) => {
  return fetch({
    url: 'api/v1/appointment/blockHours',
    shopId,
    params,
  });
};

const updateBlockHour = (shopId: string, payload: IBodyAddBlockHours) => {
  return fetch({
    method: 'post',
    url: '/api/v1/appointment/blockHours',
    shopId,
    body: { payload },
  });
};

const getQuickBookingServices = (shopId: string) => {
  return fetch({
    method: 'get',
    url: '/api/v1/service/quickBooking',
    shopId,
  });
};

const quickBooking = (shopId: string, payload: IBodyApiQuickBooking[]) => {
  return fetch({
    method: 'post',
    url: '/api/v1/appointment/quickBooking',
    body: payload,
    shopId,
  });
};

const sendSmsChangeAppointment = (shopId: string, appointmentId: string) => {
  return fetch({
    method: 'post',
    url: `/api/v1/appointment/sendSmsChange/${appointmentId}`,
    shopId,
  });
};

const sortColumnBooking = (shopId: string, body: number[]) => {
  return fetch({
    method: 'post',
    url: '/api/v1/staff/sorts-booking',
    shopId,
    body,
  });
};

const getStaffAvailableBooking = (params: { startTime: string, endTime: string }) => {
  return fetch({
    url: '/api/v1/appointment/staff-available-booking',
    params,
    shopId: storage.shopId.get(),
  });
};

const getSalonScheduler = () => {
  return fetch({
    url: '/api/v1/shop/schedulerBooking',
    shopId: storage.shopId.get(),
  });
};

const addCommissions = (body: IApiAddCommissionBody) => fetch({
  method: 'post',
  url: '/api/v1/bill/add-quick-commission',
  body,
});
const addMultipleQuickCommission = (body: IApiAddCommissionBody[]) => fetch({
  method: 'post',
  url: '/api/v1/bill/add-multiple-quick-commission',
  body,
});

const updateCommission = (body: IApiUpdateCommissionBody) => fetch({
  method: 'post',
  url: '/api/v1/bill/update-quick-commission',
  body,
});
const deleteCommissionItem = (id: number | string) => fetch({
  method: 'delete',
  url: `/api/v1/bill/delete-quick-commission/${id}`,
});

const getQuickCommissions = (params: IApiGetQuickCommissionsParams) => fetch({
  method: 'get',
  url: '/api/v1/bill/quick-commission',
  params,
});
const getSummaryQuickCommissions = (params: IApiGetQuickCommissionsParams) => fetch({
  method: 'get',
  url: '/api/v1/bill/summary-quick-commission',
  params,
});
const getOwnerReportCommissions = (from: string, to: string) => fetch({
  method: 'get',
  url: '/api/v1/report/reportShop',
  params: { from, to },
});

const getStaffReportCommissions = (date: string, staffId: string) => fetch({
  method: 'get',
  url: '/api/v1/bill/report-staff-quick-commission',
  params: { date, staffId },
});

const getSalonInfo = (shopId: string) => fetch({
  method: 'get',
  url: '/api/v1/shop/detail',
  shopId,
});


const getBlockHoursByStaffId = (staffId: string, date: string) => fetch({
  method: 'get',
  url: '/api/v1/appointment/blockHours',
  params: {
    staffId, endTime: date, startTime: date,
  }
});

const deleteBlockHours = (id: string) => fetch({
  method: 'delete',
  url: '/api/v1/appointment/deleteBlockHours/' + id,
});

const apis = {
  getCategories,
  getServices,
  getStaffs,
  getSalonInfo,
  getStaffsAvailable,
  getAppointments,
  bookAppointment,
  getTimeSlot,
  deleteApt,
  updateApt,
  getAllCustomerAutoSearchPage,
  updateDragAndDrop,
  addNewCustomer,
  getAppointmentLists,
  prolongedTime,
  checkPinCode,
  getDetailCustomer,
  updateCustomerDetail,
  addNewService,
  addBlockHours,
  getAllBlockHours,
  removeBlockHour,
  updateBlockHour,
  prolongedTimeBlockHour,
  getQuickBookingServices,
  quickBooking,
  cancelApt,
  confirmApt,
  sendSmsChangeAppointment,
  sortColumnBooking,
  getAllBlockHoursAptList,
  bookAppointmentV2,
  getStaffAvailableBooking,
  checkInApt,
  getSalonScheduler,
  addCommissions,
  updateCommission,
  deleteCommissionItem,
  getQuickCommissions,
  getSummaryQuickCommissions,
  getOwnerReportCommissions,
  getStaffReportCommissions,
  getBlockHoursByStaffId,
  deleteBlockHours,
  addMultipleQuickCommission,
};

export default apis;
