import { Modal as AntModal, Form, Input, InputRef } from 'antd';
import { forwardRef, useImperativeHandle, useRef, useState } from 'react';
import styled from 'styled-components';
import closeIcon from './Close.png';
import NumberPad from './NumberPad';
type Props = {
  onSubtract?: (turn: number) => void;
  onAdd?: (turn: number) => void;
  title?: string;
  maxValue?: number;
  enterText?: string;
};
type Ref = {
  open: (callback: (value: number) => void) => void;
  close: () => void;
};
export const useModalNumberPadRef = () => useRef<Ref>(null);
const ModalNumberPad = forwardRef<Ref, Props>(({ title, maxValue = 9, enterText }, ref) => {
  const [visible, setVisible] = useState(false);
  const inputRef = useRef<InputRef>(null);
  const [form] = Form.useForm();
  const callback = useRef<(value: number) => void>(() => undefined);
  const onClose = () => setVisible(false);
  const onOpen: Ref['open'] = (_callback) => {
    setVisible(true);
    callback.current = _callback;
    form.resetFields();
    setTimeout(() => {
      inputRef.current?.focus();
    }, 100);
  };

  const onFinish = (value: any) => {
    const number = value.number || 0;
    callback.current(number);
    onClose();
  };


  useImperativeHandle(ref, () => ({
    open: onOpen,
    close: onClose,
  }));

  return (
    <AntModal
      open={visible}
      centered
      onCancel={onClose}
      title={null}
      footer={false}
      closable={false}
      destroyOnClose={false}
      width={'620px'}
    >
      <Container initialValues={{ turn: '' }} form={form} onFinish={onFinish}>
        <div className="modal-header">
          <span>{title || 'Group Number'}</span>
          <button type='button' className='btn-close' onClick={onClose}>
            <img src={closeIcon} />
          </button>
        </div>
        <div className="modal-body">
          <div className="wrap-number-input">
            <Form.Item name={'number'} noStyle>
              <InputView inputRef={inputRef} maxValue={maxValue} />
            </Form.Item>
          </div>
          <Form.Item name={'number'} noStyle>
            <NumberPad enterText={enterText} inputRef={inputRef} maxValue={maxValue} />
          </Form.Item>
        </div>
      </Container>
    </AntModal>
  );
});
ModalNumberPad.displayName = 'ModalNumberPad';
export default ModalNumberPad;

const InputView = ({ maxValue = 9, inputRef, value, onChange = () => undefined }: { maxValue?: number, inputRef: React.RefObject<InputRef>, value?: string, onChange?: (value: any) => void }) => {
  const handleChange = (e: any) => {
    inputRef.current?.focus();
    const rawValue = e.target.value?.replace(/[^0-9]/g, ''); // Strip non-numeric characters
    if (+rawValue > maxValue) return;
    onChange(rawValue);
  };
  return (
    <Input
      ref={inputRef}
      className='number-input'
      inputMode='none'
      autoFocus
      placeholder='0'
      style={{ border: 'none', boxShadow: 'none', fontSize: 40 }}
      onChange={handleChange}
      // @ts-ignore
      onKeyPress={(event) => {
        if (!/[0-9]/.test(event.key)) {
          event.preventDefault();
        }
      }}
      // value={toCurrency(parseFloat(value || '').toFixed(2))}
      value={value}
    />
  );
};


const Container = styled(Form)`

background: #fff;

.modal-header {
  display: flex;
  padding: 0px 16px;
  justify-content: space-between;
  align-items: center;
  align-self: stretch;
  height: 46px;
  border-bottom: 2px solid #EEE;

  span {
    flex: 1 0 0;
    color: #1D2129;
    font-family: Poppins;
    font-size: 24px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
  }

  .btn-close {
    display: flex;
    width: 32px;
    height: 32px;
    padding: 5.333px;
    justify-content: center;
    align-items: center;

    img {
      height: 100%;
      width: 100%;
    }
  }
}

.modal-body {
  padding-top: 8px;

  .number-pad-placeholder {
    align-self: stretch;
    color: #7B7B7B;
    text-align: center;
    font-family: Poppins;
    font-size: 20px;
    font-style: normal;
    font-weight: 500;
    line-height: 24px;
    margin-bottom: 8px;
  }

  .wrap-number-input {
    padding: 0 16px;
    padding-bottom: 8px;
  }

  .number-input {
    height: 4rem;
    color: #1D2129;
    text-align: center;
    font-family: Poppins;
    font-size: 30px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
  }
}

.modal-footer {
  display: flex;
  padding: 16px 24px 24px 24px;
  align-items: flex-start;
  gap: 16px;
  align-self: stretch;
  border-radius: 0px 0px 5px 5px;
  background: #EEE;

  .footer-item {
    display: flex;
    height: 56px;
    padding: 10px 16px;
    justify-content: center;
    align-items: center;
    gap: 8px;
    flex: 1 0 0;
    border-radius: 2px;
    border: 1px solid #7B7B7B;
    background: #CECECE;

    span {
      color: #1D2129;
      text-align: center;
      font-family: Poppins;
      font-size: 18px;
      font-style: normal;
      font-weight: 600;
      line-height: 24px;
    }

    &.add-mode {
      border-radius: 2px;
      background: #F05326;

      span {
        color: #fff;
        text-align: center;
        font-family: Poppins;
        font-size: 18px;
        font-style: normal;
        font-weight: 700;
        line-height: 24px;
      }
    }

    &:disabled {
      opacity: 0.5;
    }
  }
}

`;