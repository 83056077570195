import { dateParamSignal } from 'CalendarFilter/DatePicker';
import { get } from 'lodash';
import moment from 'moment';
import { IScheduleBookingDateItem } from 'services/types/shop';
import { RootState } from 'store';
import { useAppSelector } from 'store/hooks';
type MyState = RootState['shop'];

const getCurrentState = (state: RootState): MyState => state['shop'];

const selector = <T = MyState>(key: keyof T, defaultValue?: any) => useAppSelector(state => get(getCurrentState(state), key, defaultValue));

const roundedMinute = (num: number) => {
  const nowTime = [0, 15, 30, 45, 60].map(o => ({ key: o, value: (num - o) })).filter(o => o.value >= 0);
  const minute = nowTime.find(o => o.value === Math.min(...nowTime.map(o => o.value)))?.key;
  return minute || 0;
};

const shopSelectors = {
  staff: () => selector('staffs') as MyState['staffs'],
  staffsObject: () => selector('staffsObject') as MyState['staffsObject'],
  salonInfo: () => selector('salonInfo') as MyState['salonInfo'],
  staffsAvailable: () => selector('staffsAvailable') as MyState['staffsAvailable'],
  customers: () => selector('customers') as MyState['customers'],
  categories: () => selector('categories') as MyState['categories'],
  allServices: () => selector('allServices') as MyState['allServices'],
  scheduleBooking: () => selector('scheduleBooking') as MyState['scheduleBooking'],
  setting: () => selector('setting') as MyState['setting'],
  salonWorkingTime: () => {
    const salonWorking = selector('scheduleBooking') as MyState['scheduleBooking'];
    const workingData: IScheduleBookingDateItem | null = get(salonWorking, dateParamSignal.value?.format('ddd') || '', null) || null;
    if (!workingData)
      return ({ timeHourStart: 0, timeHourEnd: 0, timeMinuteEnd: 0, timeMinuteStart: 0 });
    const _startTime = moment(workingData?.startTime, 'HH:mm');
    const _endTime = moment(workingData?.endTime, 'HH:mm');

    return ({
      timeHourStart: +_startTime.format('HH'),
      timeHourEnd: +_endTime.format('HH'),
      timeMinuteEnd: roundedMinute(+_startTime.format('mm')),
      timeMinuteStart: roundedMinute(+_endTime.format('mm')),
    });
  }
};
export default shopSelectors;
