import { Avatar, Form, FormListFieldData, Select, Table, TableProps } from 'antd';
import DollarAmountInput from 'components/DollarAmount';
import Text from 'components/Text';
import { sumBy } from 'lodash';
import { emptyLocaleTable } from 'Quick-Commission/EmptyTable';
import shopSelectors from 'services/selectors/shop';
import styled from 'styled-components';
import { IStaffUI } from 'types/staff';
import { formatCurrency } from 'utils/formatCurrency';
import delete_icon from './icons/delete.svg';

const deleteIcon = <img src={delete_icon} alt="delete_icon" style={{ width: 32, height: 32 }} />;
export enum QuickCommissionPaymentMethods {
  CREDIT_CARD = 1,
  CASH = 0,
  GIFT_CARD = 2,
  OTHER = 3,
}
export const payments = [
  {
    label: 'Cash',
    value: QuickCommissionPaymentMethods.CASH,
  },
  {
    label: 'Credit Card',
    value: QuickCommissionPaymentMethods.CREDIT_CARD,
  },
  {
    label: 'Gift Card',
    value: QuickCommissionPaymentMethods.GIFT_CARD,
  },
];

export type IQuickCommissionPaymentItem = {
  type: QuickCommissionPaymentMethods;
  last4Digits?: number;
  amount?: number;
};
export type IQuickCommissionTicketItem = {
  id: string;
  ticketNumber: number;
  paymentMethod: QuickCommissionPaymentMethods,
  sale: number | null;
  tip: number | null;
  discount: number | null;
  supply: number | null;
  note: string;
};
type Props = {
  tickets: FormListFieldData[];
  remove: (index: number | number[]) => void;
};

const TicketTableContent = ({ tickets, remove }: Props) => {
  const staffsObject = shopSelectors.staffsObject();

  const columns: TableProps<FormListFieldData>['columns'] = [
    {
      title: 'Staff',
      key: 'staff',
      width: 150,
      render: (record: FormListFieldData) => <PassValueForm
        name={[record.name, 'staffId']}
        render={(val) => {
          const staff = staffsObject[val] as IStaffUI;
          if (!staff) return '--';
          return <div style={{ display: 'flex', alignItems: 'center', gap: 8 }}>
            <Avatar size={32} src={staff.avatar} >{staff.name?.[0] || 'A'}</Avatar>
            <Text variant='CONTENT_2'>{staff.name}</Text>
          </div>;
        }}
      />
    },
    {
      title: 'Ticket',
      key: 'ticketNumber',
      width: 100,
      render: (record: FormListFieldData, _, index) => <TicketNumber><span>#{index + 1}</span></TicketNumber>,
    },
    {
      title: 'Sub Total',
      key: 'sale',
      render: (record: FormListFieldData) => <PassValueForm
        name={[record.name, 'sale']}
        render={(val, onChange = () => undefined) => <DollarAmountInput value={val} onChange={onChange} />}
      />
    },
    {
      title: 'Tip',
      key: 'tip',
      render: (record: FormListFieldData) => <PassValueForm
        name={[record.name, 'tip']}
        render={(val, onChange = () => undefined) => <DollarAmountInput value={val} onChange={onChange} />}
      />
    },
    {
      title: 'Discount',
      key: 'discount',
      render: (record: FormListFieldData) => <PassValueForm
        name={[record.name, 'discount']}
        render={(val, onChange = () => undefined) => <DollarAmountInput value={val} onChange={onChange} />}
      />


    },
    {
      title: 'Supply',
      key: 'note',
      render: (record: FormListFieldData) => <PassValueForm
        name={[record.name, 'supply']}
        render={(val, onChange = () => undefined) => <DollarAmountInput value={val} onChange={onChange} />}
      />
    },

    {
      title: 'Method',
      key: 'paymentMethod',
      width: 170,
      render: (record: FormListFieldData) => <PassValueForm
        name={[record.name, 'paymentMethod']}
        render={(val, onChange) => <Select style={{ height: '2rem', width: '100%' }} value={val} onChange={onChange} options={payments} />}
      />
    },
    {
      title: 'Total',
      key: 'note',
      width: 150,
      render: (record: FormListFieldData) => (
        <Form.Item shouldUpdate noStyle>
          {({ getFieldValue }) => {
            const val: IQuickCommissionTicketItem = getFieldValue(['tickets', record.name]);
            return (
              <div style={{ fontSize: 18, fontWeight: '600', textAlign: 'right' }}>
                {formatCurrency((val.sale || 0) + (val.tip || 0) - (val.discount || 0) + (val.supply || 0))}
              </div>
            );
          }}
        </Form.Item>
      )
    },
    {
      title: 'Action',
      key: 'action',
      width: 100,
      align: 'center',
      render: (record: FormListFieldData) => {
        return <button onClick={() => remove(record.name)} type='button' className='btn-delete'>{deleteIcon}</button>;
      },
    },
  ];
  return (
    // @ts-ignore
    <TableStyled
      key={'key'}
      dataSource={tickets}
      // scroll={{ y: '40vh' }}
      // @ts-ignore
      columns={columns}
      pagination={false}
      locale={emptyLocaleTable}

      summary={() => {
        return (
          <Form.Item shouldUpdate noStyle>
            {({ getFieldValue }) => {
              const tickets: IQuickCommissionTicketItem[] = getFieldValue('tickets');
              return (
                <Table.Summary fixed>
                  <Table.Summary.Row>
                    <Table.Summary.Cell colSpan={2} index={0}>
                      <span>Total</span>
                    </Table.Summary.Cell>
                    <Table.Summary.Cell className='value' index={1}><span>{formatCurrency(sumBy(tickets, o => (o.sale || 0)))}</span></Table.Summary.Cell>
                    <Table.Summary.Cell className='value' index={1}><span>{formatCurrency(sumBy(tickets, o => (o.tip || 0)))}</span></Table.Summary.Cell>
                    <Table.Summary.Cell className='value' index={1}><span>{formatCurrency(sumBy(tickets, o => (o.discount || 0)))}</span></Table.Summary.Cell>
                    <Table.Summary.Cell className='value' index={1}><span>{formatCurrency(sumBy(tickets, o => (o.supply || 0)))}</span></Table.Summary.Cell>
                    <Table.Summary.Cell className='value' index={1}></Table.Summary.Cell>
                    <Table.Summary.Cell className='value' index={1}><span>{formatCurrency(sumBy(tickets, o => (o.sale || 0) + (o.tip || 0) - (o.discount || 0) + (o.supply || 0)))}</span></Table.Summary.Cell>
                    <Table.Summary.Cell className='value' index={1}></Table.Summary.Cell>
                  </Table.Summary.Row>
                </Table.Summary>
              );
            }}
          </Form.Item>

        );
      }}
    />
  );
};

export default TicketTableContent;

const TicketNumber = styled.div`
  background: #1D2129;
  border-radius: 5px;
  display: flex;
  justify-content: center;
  span {
    color: #fff;
  }
`;
const TableStyled = styled(Table)`
&.ant-table-wrapper .ant-table-summary {
  position: sticky;
  bottom: 100px;
  z-index: 99;
  background: #ffffff;
}
&.ant-table-wrapper .ant-table-thead>tr>th, .ant-table-wrapper .ant-table-thead>tr>th{
  border-bottom: 1px solid #CCD4DC;
  border-right: 1px solid #FFF;
  background: #484F58;
  color: #FFF;
  font-family: Poppins;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 20px;
  padding: 8px;
  position: sticky;
  top: 80px;
  z-index: 99;
}
.ant-table-tbody>tr>td.ant-table-cell {
  padding: 0px 16px;

  .btn-delete {
    height: 48px;
    width: 48px;
    margin: auto;
    border-radius: 4px;
    display: flex;
    align-items: center;
    justify-content: center;
    &:active {
      background: #eee;
    }
  }
  .dollar-input-selector {
    justify-content: flex-end;
    height: auto;
  }
  .ant-input {
    width: 100%;
    height: 2rem;
    text-align: right;
  }
}

&.ant-table-wrapper .ant-table-summary .ant-table-cell {
  color: #1D2129;
  font-family: Poppins;
  font-size: 20px;
  font-style: normal;
  font-weight: 600;
  line-height: 20px;
  &.value {
    text-align: right;
  }
}
`;
const ReceiveValueForm = ({ value, render, onChange }: { onChange?: (value: any) => void, value?: any, render?: (value: any, onChange?: (value: any) => void) => any }) => render ? render(value, onChange) : value;
const PassValueForm = ({ name, render }: { name?: any, render?: (value: any, onChange?: (value: any) => void) => any }) => <Form.Item noStyle name={name}><ReceiveValueForm render={render} /></Form.Item>;
