import { Table } from 'antd';
import commSelectors from 'services/selectors/comm';
import { formatCurrency } from 'utils/formatCurrency';

const SummaryTotal = () => {
  const summaryData = commSelectors.summaryCommissions();
  return (
    <>
      <Table.Summary.Row>
        <Table.Summary.Cell className='total' align='left' colSpan={3} index={0}>
          <span>Total</span>
        </Table.Summary.Cell>
        <Table.Summary.Cell className='value' index={1}><span></span></Table.Summary.Cell>
        <Table.Summary.Cell className='value' index={1}><span>{formatCurrency(summaryData?.subTotal)}</span></Table.Summary.Cell>
        <Table.Summary.Cell className='value' index={1}><span>{formatCurrency(summaryData?.tip)}</span></Table.Summary.Cell>
        <Table.Summary.Cell className='value' index={1}><span>{formatCurrency(summaryData?.discount)}</span></Table.Summary.Cell>
        <Table.Summary.Cell className='value' index={1}><span>{formatCurrency(summaryData?.supplyFee)}</span></Table.Summary.Cell>
        <Table.Summary.Cell className='value' index={1}><span>{formatCurrency(summaryData?.total)}</span></Table.Summary.Cell>
        <Table.Summary.Cell className='action' index={1}><span></span></Table.Summary.Cell>
      </Table.Summary.Row>
    </>
  );
};

export default SummaryTotal;
