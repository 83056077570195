import { get } from 'lodash';
import { RootState } from 'store';
import { useAppSelector } from 'store/hooks';
type MyState = RootState['comm'];

const getCurrentState = (state: RootState): MyState => state['comm'];

const selector = <T = MyState>(key: keyof T, defaultValue?: any) => useAppSelector(state => get(getCurrentState(state), key, defaultValue));

const commSelectors = {
  getPureParams: (state: RootState) => state['comm']?.params || {},
  state: () => useAppSelector(getCurrentState),
  loadingTable: () => selector('loadingTable') as boolean,
  params: () => selector('params') as MyState['params'],
  reportOwner: () => selector('reportOwner') as MyState['reportOwner'],
  reportStaff: () => selector('reportStaff') as MyState['reportStaff'],
  summaryCommissions: () => selector('summaryCommissions') as MyState['summaryCommissions'],
};
export default commSelectors;
